import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Image } from '@chakra-ui/react';
import loadable from '@loadable/component';

import { ASSETS_FLOWER_2A } from '@/constants/assets';

import { photos as photosLive } from './api/photos-2';
import WithAnimation from '@/components/Common/WithAnimation';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

function PhotoSection() {
  return (
    <Box padding="2px 0 0 0" bgSize="cover" pos="relative">
      <Box pos="absolute" right="-20px" bottom="50px" zIndex="5" transform="rotateY(180deg)">
        <WithAnimation right>
          <Image src={ASSETS_FLOWER_2A} width="110px" style={{ transform: 'rotate(36deg)' }} />
        </WithAnimation>
      </Box>
      <WithAnimation type="zoom">
        <Center>
          <Box padding="32px 0 0 0" width="85%">
            <LoadableGallerry data={photosLive} />
          </Box>
        </Center>
      </WithAnimation>
    </Box>
  );
}

PhotoSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(PhotoSection);