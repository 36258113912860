import get from '@invitato/helpers/dist/getLocale';

export default {
  thanks: get('Terima Kasih', 'Thank You!'),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Sampai jumpa di hari bahagia kami!', 
    'It will be a joy for us if you are able to be attend on our wedding day. Thank you for all the kind words, prayers, and attentions given. See you on our wedding day!'
  ),
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.', 
    `It will be a joy for us if you are still willing to give your blessing from afar.`
  ),
};