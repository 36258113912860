import React from 'react';

import { object, string } from 'prop-types';
import { Box, Center, Text, Image } from '@chakra-ui/react';

import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';

import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import { HEADING_STYLES, CONTENT_STYLES } from '@/constants/typography';
import {
  ASSETS_FRAME,
  ASSETS_BUTTERFLY
} from '@/constants/assets';

import { stylePhoto } from './styles'

function DetailCouple(props) {
  const {
    imgUrl,
    instagramId,
    parentName,
    fullName,
    instagramIdProps,
    fullNameProps,
    parentNameProps,
    i,
    ...rest
  } = props;

  return (
    <Box pos="relative" {...rest} css={stylePhoto}>

      {/* photo frame */}
      <WithAnimation type="zoom">
        <Box pos="relative">
          <Box 
            bgImage={`url("${imgUrl}")`} 
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            pos="relative"
            zIndex="2"
            className="photo-frame"
            height="400px">
          </Box>
          <Box 
            pos="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="15"
            width="300px"
          >
            <Image
              src={ASSETS_FRAME}
              width="100%"/>
            {/* ASSETS */}
            <Box 
              pos="absolute" 
              top="88px" 
              left={i.index === 0 ? '10px': ''} 
              right={i.index === 1 ? '10px': ''} 
              zIndex="20" 
              className="animation-up-and-down"
            >
                <WithAnimation>
                  <Image src={ASSETS_BUTTERFLY} width="80px" transform={i.index === 1 ? 'rotateY(180deg)': ''} />
                </WithAnimation>
            </Box>

          </Box>
        </Box>
      </WithAnimation>
     
      {/* info */}
      <Box padding="24px" pos="relative" zIndex="5" textAlign="center">
        <WithAnimation>
          <Text
            {...HEADING_STYLES}
            {...fullNameProps}
          >
            {fullName}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text
            {...CONTENT_STYLES}
            pt="8px"
            dangerouslySetInnerHTML={{ __html: parentName }}
            {...parentNameProps}
          />
        </WithAnimation>
        {instagramId && (
          <Center marginTop="24px">
            <WithAnimation>
              <InstagramButton
                id={instagramId}
                text={instagramId}
                color="white"
                onlyIcon={false}
                size="md"
                {...BUTTON_PADDING_PROPS}
                {...instagramIdProps}
                fontSize="md"
              />
            </WithAnimation>
          </Center>
        )}
      </Box>
    </Box>
  );
}

DetailCouple.propTypes = {
  imgUrl: string.isRequired,
  instagramId: string,
  parentName: string,
  fullName: string,
  i: object,
  instagramIdProps: object,
  fullNameProps: object,
  parentNameProps: object,
};

DetailCouple.defaultProps = {
  instagramId: '',
  fullName: '',
  instagramIdProps: {},
  fullNameProps: {},
  parentName: '',
  parentNameProps: {},
  i: {index:0}
};

export default DetailCouple;
