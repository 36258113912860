import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Textarea,
  Flex,
  FormLabel,
  Spacer,
  // hooks
  useToast,
  Text,
  Image
} from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Wishlist from './WishlishCard';

import { useGuest } from '@/context/guest';
import useWishes from '@/usecase/use-wishes';
import useFormFields from '@/hooks/useFormFields';
import useInvitation from '@/hooks/useInvitation';

import txtWording from './locales';
import { styleWishlish } from './styles';
import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import { INPUT_COMMON_PROPS, ERROR_TYPE, FORM_LABEL_PROPS } from './types';
import { ASSETS_BUTTERFLY, ASSETS_FLOWER_3 } from '@/constants/assets';
import { TITLE_SECTION_STYLES, CONTENT_STYLES } from '@/constants/typography'

function WishesSection({ lang }) {
  const { guest, isRegistered } = useGuest();
  const { code, name } = guest;
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const { isLoading, isMutating, data, error, onPostWish, onRefetch } = useWishes();
  const isInvitation = useInvitation();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    wish: '',
  });

  useEffect(() => {
    onResetForm();
  }, [isRegistered]);

  const toast = useToast();

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
        isClosable: true,
      });
    }
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate input data
    if (!formFields.name || !formFields.wish) {
      setErrorType({
        name: !formFields.name && txtWording.requiredField[lang],
        wish: !formFields.wish && txtWording.requiredField[lang],
      });
      return;
    }

    await onPostWish({
      code,
      name: formFields.name,
      wish: formFields.wish,
      onError: () => {
        handleSetAlert(false);
      },
      onSuccess: () => {
        onResetForm();
        handleSetAlert(true);
        setErrorType(ERROR_TYPE);
        onRefetch();
      },
    });
  };

  return (
    <Box padding="24px" marginTop="12px" pos="relative" css={styleWishlish}>
      <WithAnimation>
        <Heading
           {...TITLE_SECTION_STYLES}
           fontSize="40px"
        >
          {txtWording.title[lang]}
        </Heading>
      </WithAnimation>
      <WithAnimation>
        <Text
          {...CONTENT_STYLES}
          align="center"
          padding="8px 12px 16px 12px"
          fontStyle="italic">
          {txtWording.desc[lang]}
        </Text>
      </WithAnimation>
      <Box pos="relative">
        {/* Box for FORM */}
        <WithAnimation>
          <Box padding="16px 0">
            <FormControl margin="8px 0" isInvalid={errorType.name}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={createChangeHandler('name')}
                value={formFields.name}
              />
              <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
            </FormControl>
            <FormControl margin="16px 0" isInvalid={errorType.ucapan}>
              <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}</FormLabel>
              <Textarea
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                onChange={createChangeHandler('wish')}
                value={formFields.wish}
              />
              <FormErrorMessage marginTop="4px">{errorType.ucapan}</FormErrorMessage>
            </FormControl>
            <Flex justifyItems="end" py="24px">
              <Spacer />
              <Button
                isLoading={isMutating}
                onClick={handleSubmit}
                {...BUTTON_PADDING_PROPS}
              >
                {txtWording.send[lang]}
              </Button>
            </Flex>
          </Box>
        </WithAnimation>
        {/* Wishlist Card */}
        {!error && (
          <Box pos="relative">
            <Box className="assets-butterfly">
              <WithAnimation left>
                <Image src={ASSETS_BUTTERFLY} className="assets-butterfly-img" />
              </WithAnimation>
            </Box>
            <Box className="assets-flower">
              <WithAnimation right>
                <Image src={ASSETS_FLOWER_3} className="assets-flower-img" />
              </WithAnimation>
            </Box>
            <WithAnimation>
              <Wishlist wishlistData={data} loading={isLoading} />
            </WithAnimation>
          </Box>
        )}
      </Box>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
