import { css } from '@emotion/react';
import frame from '@/images/assets/path.svg'

export const stylePhoto = css`
  .photo-frame {
    mask-image: url("${frame}");
    -webkit-mask-image: url("${frame}");
    mask-size: 240px;
    -webkit-mask-size: 240px;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
  }
`