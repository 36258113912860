import React, { useMemo } from 'react';
import { scroller } from 'react-scroll';
import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Text,
  Divider,
  DrawerFooter,
  Heading,
  // hooks
  useMediaQuery,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdClose, MdMenu } from 'react-icons/md';

import useLang from '@/hooks/useLang';
import useInvitation from '@/hooks/useInvitation';

import { THE_BRIDE } from '@/constants';
import { BUTTON_PROPS, NAVIGATION_COLOR, ICON_NAVIGATION_COLOR, GRADIENT_COVER } from '@/constants/colors';

import useMenudata from './useMenuData';
import txt from './locales';

import { TITLE_SECTION_STYLES, HEADING_STYLES } from '@/constants/typography'

function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDesktopView] = useMediaQuery('(min-width: 1024px)');

  const menuData = useMenudata();
  const toast = useToast();
  const lang = useLang();

  const btnRef = React.useRef();
  const isInvitation = useInvitation();

  const filteredMenuData = useMemo(
    () =>
      menuData.filter((i) => {
        if (isInvitation) return true;
        if (!i.invitationOnly) return true;
        return false;
      }),
    [],
  );

  const onClickMenu = (ELEMENT_CONTENT_ID, offset) => {
    try {
      onClose();
      // const element = document.getElementById(ELEMENT_CONTENT_ID).offsetTop;
      scroller.scrollTo(ELEMENT_CONTENT_ID, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
      });
    } catch {
      toast({
        title: 'Oops!',
        description: txt.menuNotFound[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IconButton
        ref={btnRef}
        borderRadius="50%"
        position="fixed"
        zIndex="12"
        bottom="12px"
        left="12px"
        size="sm"
        boxShadow="xl"
        onClick={isOpen ? onClose : onOpen}
        bgColor={NAVIGATION_COLOR}
        icon={
          isOpen ? (
            <MdClose color={ICON_NAVIGATION_COLOR} />
          ) : (
            <MdMenu color={ICON_NAVIGATION_COLOR} />
          )
        }
        {...BUTTON_PROPS}
      />
      <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          maxWidth={isDesktopView ? '30%' : '85%'}
          bgImage={GRADIENT_COVER}
          bgSize="contain"
          padding="24px 16px"
        >
          <DrawerBody textAlign="right">
            <Box display="flex" justifyContent="end">
              <Box position="relative" width="250px">
                <Heading
                  {...TITLE_SECTION_STYLES}
                  color="white"
                  textTransform="lowercase"
                  position="relative"
                  textAlign="right"
                >
                  the 
                </Heading>
                <Heading
                  {...TITLE_SECTION_STYLES}
                  color="white"
                  padding="4px 0"
                  textTransform="lowercase"
                  position="relative"
                  mt="-50px"
                  textAlign="right"
                >
                  wedding of
                </Heading>
              </Box>
            </Box>
            <Text
              {...HEADING_STYLES}
              fontSize="28px"
              color="white"
              py="16px"
              mb="60px"
              fontStyle="italic"
            >
              {THE_BRIDE}
            </Text>
            {filteredMenuData.map((i) => {
              return (
                <div key={i.value}>
                  <Text
                    margin="24px 0"
                    color="white"
                    letterSpacing="3px"
                    fontSize="lg"
                    textTransform="uppercase"
                    cursor="pointer"
                    _hover={{ color: "mainColorText"}}
                    onClick={() => onClickMenu(i.value, i.offset)}
                  >
                    {i.name}
                  </Text>
                  <Divider
                    bgColor="white"
                    borderBottom="none"
                    height="2px"
                    width="100%"
                  />
                </div>
              );
            })}
          </DrawerBody>
          <DrawerFooter>
            <Box textAlign="right" fontSize="md" fontFamily="bodyAlternative">
              <Text color="white">Created with Love by Invitato</Text>
              <Text color="white">{`${new Date().getFullYear()} ${THE_BRIDE}`}</Text>
              <Text color="white">All Rights Reserved</Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navigation;
