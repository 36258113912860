import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Wedding Gift', 'wedding gift'),
  desc: get(
    `Bagi yang ingin memberikan tanda kasih, dapat mengirimkan melalui fitur di bawah ini:`,
    `For beloved ones who may want to show your sincere love by sending a gift, please kindly tap the button below:`,
  ),
  confirm: get('Konfirmasi', 'Confirmation'),
  sendGift: get('Kirim Hadiah', 'Send Gift'),
  address: get('Alamat', 'Address'),
  modalDesc: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga dapat melakukan transfer melalui rekening berikut:`,
    `For beloved one who may want to show your sincere love with sending gift, please kindly tap the button down below:`,
  ),
  modalCopy: get('Salin Nomor Rekening', 'Copy Account Number'),
  modalCopyAddress: get('Salin Alamat', 'Copy Address'),
  modalDescAddress: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga dapat mengirimkan hadiah melalui alamat berikut:`,
    `For beloved one who may want to show your sincere love with sending gift, please kindly send to this address below:`,
  ),
  success: get('Sukses!', 'Success!'),
  successCopyRekening: get(
    'Nomor rekening berhasil disalin.',
    'Account number copied successfully.',
  ),
  successCopyAddress: get(
    'Alamat berhasil disalin.',
    'Address copied successfully.',
  ),
  failedCopied: get(
    'Terjadi kesalahan. Mohon muat ulang browser Anda atau coba lagi nanti.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
};