import React from 'react';
import { string } from 'prop-types';
import { Box, Text, Image, Heading } from '@chakra-ui/react';

import QRCard from '@/components/Common/QRCard/Lazy';
import WithAnimation from '@/components/Common/WithAnimation';
import ViewLocationButton from '@/components/Common/Buttons/ViewLocation';

import { useGuest } from '@/context/guest';
import generateShift from '@/hooks/useShift';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';

import {
  ASSETS_RIBBON_PINK,
  ASSETS_FLOWER_2A,
  ASSETS_FLOWER_1,
  ASSETS_BUTTERFLY
} from '@/constants/assets';
import { ENABLE_QR_INVITATION } from '@/constants/feature-flags';

import {
  WEDDING_AKAD_DAY,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_AKAD_DRESSCODE,
  WEDDING_AKAD_NOTE,
  GOOGLE_MAPS_AKAD,
  WEDDING_RESEPSI_DAY,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_DRESSCODE,
  WEDDING_RESEPSI_NOTE,
  GOOGLE_MAPS_RESEPSI,
} from '@/constants';

import { ID_QRCODE } from '@/constants/identifier';

import { TITLE_SECTION_STYLES, CONTENT_STYLES } from '@/constants/typography'
import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import txt from './locales';
import { Fragment } from 'react/cjs/react.production.min';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const { guest } = useGuest();

  const isInvitation = useInvitation();
  const shiftTime = generateShift(guest.shift);
  const lang = useLang();

  return (
    <WithAnimation type="zoom">
      <Fragment>
        <Box padding="32px 0px" marginBottom="-120px" width="100%" pos="relative" {...rest}>
          {/* AKAD SECTION */}
          <Box pos="relative" zIndex="3">
            <WithAnimation type="zoom">
              <Heading 
                {...TITLE_SECTION_STYLES} fontSize="50px" lineHeight="1.6em">
                {isInvitation ? txt.akad[lang] : txt.reception[lang]}
              </Heading>
            </WithAnimation>
          </Box>
          <WithAnimation type="zoom">
            <Box
              bgImage={ASSETS_RIBBON_PINK}
              bgSize="contain" 
              bgPos="center"
              bgRepeat="no-repeat"
              pos="relative"
              zIndex="2"
              height="650px"
              mt="-110px"
            >
              <Box 
                width="200px" 
                mx="auto" 
                textAlign="center" 
                paddingTop="200px" 
                pos="relative" 
                minHeight="430px">
                <WithAnimation type="zoom">
                  <Box {...CONTENT_STYLES} paddingTop={isInvitation ? '0px': '30px'}>
                    <Text>{isInvitation ? `Jum'at` : WEDDING_AKAD_DAY},</Text>
                    <Text>{isInvitation ? `26 April 2024` : WEDDING_AKAD_FULLDATE}</Text>
                    <Text>{isInvitation ? WEDDING_AKAD_TIME : shiftTime}</Text>
                  </Box>
                </WithAnimation>
                {!isInvitation && (
                  <>
                    <WithAnimation type="zoom">
                      <Box {...CONTENT_STYLES} my="12px">
                        <Text fontSize="20px" fontStyle="italic" color="alternativeColorText">
                          {WEDDING_RESEPSI_LOC_NAME}
                        </Text>
                        <Text fontSize="14px">{WEDDING_RESEPSI_LOC_ROAD}</Text>
                      </Box>
                      {WEDDING_AKAD_DRESSCODE && <Text {...CONTENT_STYLES} fontSize="14px">Dresscode: {WEDDING_AKAD_DRESSCODE}</Text>}
                    </WithAnimation>
                    {false && (
                      <Box pos="absolute" zIndex="10" bottom="0" left="50%" transform="translateX(-50%)">
                        <WithAnimation>
                          <ViewLocationButton
                            marginTop="16px"
                            text={txt.seeLocation[lang]}
                            href={GOOGLE_MAPS_AKAD}
                            {...BUTTON_PADDING_PROPS}
                            fontSize="14px"
                            padding="16px 12px"
                            size="xs"
                          />
                        </WithAnimation>
                      </Box>
                    )}
                  </>
                )}
                {isInvitation && (
                  <>
                    <WithAnimation type="zoom">
                      <Box {...CONTENT_STYLES} my="12px">
                        <Text fontSize="20px" fontStyle="italic" color="alternativeColorText">
                          {WEDDING_AKAD_LOC_NAME}
                        </Text>
                        <Text fontSize="14px">{WEDDING_AKAD_LOC_ROAD}</Text>
                      </Box>
                      {WEDDING_AKAD_DRESSCODE && <Text {...CONTENT_STYLES} fontSize="14px">Dresscode: {WEDDING_AKAD_DRESSCODE}</Text>}
                    </WithAnimation>
                    {false && (
                      <Box pos="absolute" zIndex="10" bottom="0" left="50%" transform="translateX(-50%)">
                        <WithAnimation>
                          <ViewLocationButton
                            marginTop="16px"
                            text={txt.seeLocation[lang]}
                            href={GOOGLE_MAPS_AKAD}
                            {...BUTTON_PADDING_PROPS}
                            fontSize="14px"
                            padding="16px 12px"
                            size="xs"
                          />
                        </WithAnimation>
                      </Box>
                    )}
                  </>
                )}
              </Box>

              {/* ASSETS */}
              <Box width="200px" mx="auto" minHeight="300px" pos="relative" marginTop="-180px">
                <Box pos="absolute" left="-50px" top="-70px" width="100px" height="100px">
                  <WithAnimation left>
                    <Image src={ASSETS_BUTTERFLY} width="80%" className="animation-up-and-down" />
                  </WithAnimation>
                </Box>
                <Box pos="absolute" left="-150px" bottom="-55px" width="250px" height="350px">
                  <WithAnimation type="zoom">
                    <Image src={ASSETS_FLOWER_2A} width="100%" />
                  </WithAnimation>
                </Box>
                <Box pos="absolute" right="-130px" top="-90px" width="200px" height="350px" className='animation__flower'>
                  <WithAnimation type="zoom">
                    <Image src={ASSETS_FLOWER_1} width="100%" />
                  </WithAnimation>
                </Box>
              </Box>
            </Box>
          </WithAnimation>
          {/* RECEPTION NOTE */}
          {isInvitation && WEDDING_AKAD_NOTE && (
            <Text 
              {...CONTENT_STYLES} 
              fontSize="18px" 
              fontStyle="italic" 
              color="mainColorText" 
              textAlign="center"
              marginTop="-90px"
              marginBottom="100px">
              {WEDDING_AKAD_NOTE}
          </Text>)}

          {/* RESEPSI SECTION */}
          {isInvitation && (
            <WithAnimation type="zoom">
              <Box pos="relative" zIndex="3" mt="-42px">
                <WithAnimation type="zoom">
                  <Heading 
                    {...TITLE_SECTION_STYLES} fontSize="50px" lineHeight="1.6em">
                    {txt.reception[lang]}
                  </Heading>
                </WithAnimation>
              </Box>

              <Box
                bgImage={ASSETS_RIBBON_PINK}
                bgSize="contain" 
                bgPos="center"
                bgRepeat="no-repeat"
                pos="relative"
                zIndex="2"
                height="650px"
                mt="-120px"
              >
                {/* DETAILS */}
                <Box 
                  width="200px" 
                  mx="auto" 
                  textAlign="center" 
                  paddingTop="200px" 
                  pos="relative" 
                  minHeight="430px">
                  <WithAnimation type="zoom">
                    <Box {...CONTENT_STYLES}>
                      <Text>{WEDDING_RESEPSI_DAY},</Text>
                      <Text>{WEDDING_RESEPSI_FULLDATE}</Text>
                      <Text>{shiftTime}</Text>
                    </Box>
                  </WithAnimation>
                  {isInvitation && (
                    <>
                      <WithAnimation type="zoom">
                        <Box {...CONTENT_STYLES} my="12px">
                          <Text fontSize="20px" fontStyle="italic" color="alternativeColorText">
                            {WEDDING_RESEPSI_LOC_NAME}
                          </Text>
                          <Text fontSize="14px">{WEDDING_RESEPSI_LOC_ROAD}</Text>
                        </Box>
                        {WEDDING_RESEPSI_DRESSCODE && <Text {...CONTENT_STYLES} fontSize="14px">Dresscode: {WEDDING_RESEPSI_DRESSCODE}</Text>}
                      </WithAnimation>
                      <Box pos="absolute" zIndex="10" bottom="0" left="50%" transform="translateX(-50%)">
                        <WithAnimation>
                          <ViewLocationButton
                            marginTop="16px"
                            text={txt.seeLocation[lang]}
                            href={GOOGLE_MAPS_RESEPSI}
                            {...BUTTON_PADDING_PROPS}
                            fontSize="14px"
                            padding="16px 12px"
                            size="xs"
                          />
                        </WithAnimation>
                      </Box>
                    </>
                  )}
                </Box>

                {/* ASSETS */}
                <Box width="200px" mx="auto" minHeight="300px" pos="relative" marginTop="-180px">
                  <Box pos="absolute" right="-50px" top="-70px" width="100px" height="100px"  transform="rotateY(180deg)">
                    <WithAnimation left>
                      <Image src={ASSETS_BUTTERFLY} width="80%" className="animation-up-and-down" />
                    </WithAnimation>
                  </Box>
                  <Box pos="absolute" right="-150px" bottom="-55px" width="250px" height="350px" transform="rotateY(180deg)">
                    <WithAnimation type="zoom">
                      <Image src={ASSETS_FLOWER_2A} width="100%" />
                    </WithAnimation>
                  </Box>
                  <Box pos="absolute" left="-130px" top="-90px" width="200px" height="350px"  className='animation__flower'>
                    <WithAnimation type="zoom">
                      <Box transform="rotateY(180deg)">
                        <Image src={ASSETS_FLOWER_1} width="100%" />
                      </Box>
                    </WithAnimation>
                  </Box>
                </Box>
              </Box>
            </WithAnimation>
          )}

          {/* RECEPTION NOTE */}
          {isInvitation && WEDDING_RESEPSI_NOTE && (
            <Text 
              {...CONTENT_STYLES} 
              fontSize="18px" 
              fontStyle="italic" 
              color="mainColorText" 
              textAlign="center"
              marginTop="-90px"
              marginBottom="100px">
              {WEDDING_RESEPSI_NOTE}
          </Text>)}
        </Box>
        
        {/* @TODO: QRCODE IMPLEMENTATION */}
        {isInvitation && ENABLE_QR_INVITATION && (
          <div name={ID_QRCODE}>
            <WithAnimation>
              <Box padding="0 32px">
                <QRCard marginTop="32px" />
              </Box>
            </WithAnimation>
          </div>
        )}
        <Box height="42px" />
      </Fragment>
    </WithAnimation>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);
