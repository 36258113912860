import React, { useState } from 'react';

import { string } from 'prop-types';
import { Box, Text, Heading, Center, Button, useToast, Image } from '@chakra-ui/react';

import useLang from '@/hooks/useLang';
import WithAnimation from '@/components/Common/WithAnimation';
import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';

import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import { TITLE_SECTION_STYLES, CONTENT_STYLES } from '@/constants/typography';
import { ENABLE_GIFT_CARD } from '@/constants/feature-flags';

import txtWording from './locales';
import ModalGiftCard from './ModalGiftCard';
import { ASSETS_LEAF, ASSETS_FLOWER_1 } from '@/constants/assets';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  open: 'open',
  confirm: 'confirmation',
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();
  const lang = useLang();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box {...rest} marginTop="32px" pos="relative">
      {/* ASSETS LEFT */}
      <Box position="absolute" top="20px" left="-120px" zIndex="2" className='animation__flower'>
        <WithAnimation type="zoom">
          <Image src={ASSETS_LEAF} width="250px"></Image>
        </WithAnimation>
      </Box>
      <Box position="absolute" top="35px" left="-75px" zIndex="5">
        <WithAnimation type="zoom" delay={1000}>
          <Image src={ASSETS_FLOWER_1} width="170px"></Image>
        </WithAnimation>
      </Box>
      
      <Box padding="24px" width="350px" mx="auto">
        <Center>
          <WithAnimation>
            <Heading
              {...TITLE_SECTION_STYLES}
              px="8px"
              fontSize="40px"
              marginBottom="-16px"
            >
              {txtWording.title[lang]}
            </Heading>
          </WithAnimation>
        </Center>
        <WithAnimation>
          <Text
            {...CONTENT_STYLES}
            align="center"
            margin="16px 0"
            padding="0 28px"
            fontStyle="italic"
          >
            Doa restu Anda merupakan karunia yang sangat berarti bagi kami. <br />
            Jika memberi adalah ungkapan tanda kasih, Anda dapat memberi kado secara <i>cashless</i> melalui fitur di bawah ini:
          </Text>
        </WithAnimation>
        
        {ENABLE_GIFT_CARD && (
          <Center>
            <WithAnimation>
              <Button
                {...BUTTON_PADDING_PROPS}
                fontStyle="italic"
                my={2}
                onClick={() => setModalState(MODAL_STATE.open)}
              >
                {txtWording.sendGift[lang]}
              </Button>
            </WithAnimation>
          </Center>
        )}
      </Box>
      
      <ModalGiftCard
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.open}
        onCopyText={onCopyText}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: 'en',
};

export default React.memo(GiftCardSection);
