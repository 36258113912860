import React from 'react';
import { Box, Text, Image, Heading } from '@chakra-ui/react';

import useLang from '@/hooks/useLang';
import { useGuest } from '@/context/guest';
import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@/hooks/useInvitation';

import { ENABLE_MUSLIM_LANGUAGE } from '@/constants/feature-flags';
import locales from './locales';
import { CONTENT_STYLES, TITLE_SECTION_STYLES } from '@/constants/typography'
import { ASSETS_FLOWER_3 } from '@/constants/assets';

function WelcomeV4() {
  const { guest } = useGuest();
  const { name } = guest;
  const lang = useLang();
  const invitation = useInvitation();

  const textAnnouncement = ENABLE_MUSLIM_LANGUAGE
    ? locales.welcomingAnnouncementMuslim[lang]
    : locales.welcomingAnnouncementGeneral[lang];

  const textInvitation = ENABLE_MUSLIM_LANGUAGE
    ? locales.welcomingInvitationMuslim[lang]
    : locales.welcomingInvitationGeneral[lang];

  return (
    <Box
      bgImage="linear-gradient(to bottom, #F4EAE1 20%, #FFF8F1 40%, transparent 70%)"
      bgSize="contain"
      bgPos="center"
      padding="24px"
      minHeight="80vh"
    >
      {/* welcome section */}
      <Box textAlign="center" padding="24px 0px" {...CONTENT_STYLES}>
        <WithAnimation>
          <Text>{locales.dear[lang]}</Text>
          <Text>{name ? `${name}` : locales.guest[lang]}</Text>
        </WithAnimation>
        <WithAnimation>
          <Box my="16px">
            <Text>{invitation ? textInvitation : textAnnouncement}</Text>
          </Box>
        </WithAnimation>
      </Box>

      {/* couple name section */}
      <Box position="relative" display="flex" justifyContent={"flex-end"}>
        <Box position="absolute" top="-36px" left="-170px">
          <WithAnimation type="zoom">
            <Image src={ASSETS_FLOWER_3} width="250px" />
          </WithAnimation>
        </Box>

        <Box width="370px">
          <WithAnimation>
            <Box mx="16px">
              <Heading {...TITLE_SECTION_STYLES} textAlign="right" color="alternativeColorText" lineHeight="1.6em" fontSize="40px">Munika <br />Nur Tridayanti</Heading>
              <Heading {...TITLE_SECTION_STYLES} textAlign="right" color="mainColorText" lineHeight="1.6em" fontSize="36px" textTransform="lowercase" pr="4px">&</Heading>
              <Heading {...TITLE_SECTION_STYLES} textAlign="right" color="alternativeColorText" lineHeight="1.6em" fontSize="40px">Muhammad <br />Alif Asshidiqi</Heading>
            </Box>
          </WithAnimation>
        </Box>
      </Box>

      {/* Ayat */}
      <WithAnimation>
        <Box textAlign="center" mt="30px" {...CONTENT_STYLES}>
          <Text>
            {ENABLE_MUSLIM_LANGUAGE ? `"${locales.ayatMuslim[lang]}"` : `"${locales.ayat[lang]}"`}
          </Text>
          <Text pt="20px">
            {ENABLE_MUSLIM_LANGUAGE ? `(${locales.ayatNameMuslim[lang]})` : `(${locales.ayatName[lang]})`}
          </Text>
        </Box>
      </WithAnimation>
    </Box>
  );
}

export default React.memo(WelcomeV4);
