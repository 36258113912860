import React, { useState, useEffect } from 'react';

import { Box, Center, Heading, Text, Image } from '@chakra-ui/react';
import { func } from 'prop-types';

import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import { useGuest } from '@/context/guest';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import ImgBismillah from '@/images/bismillah.png';

// import {  } from '@/constants/assets';
import { BUTTON_PADDING_PROPS, GRADIENT_COVER } from '@/constants/colors';
import { THE_BRIDE, WEDDING_RESEPSI_FULLDATE } from '@/constants';

import txt from './locales';
import { TITLE_SECTION_STYLES, HEADING_STYLES, CONTENT_STYLES } from '@/constants/typography'
import { ASSETS_BUILDING, ASSETS_FLOWER_1, ASSETS_BUTTERFLY } from '@/constants/assets';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 }
 */
function Cover({ onSeeDetail }) {
  const { isLoading } = useGuest();
  const [loading, setLoading] = useState(false);
  const isInvitation = useInvitation();
  const lang = useLang();

  const handleLoadingComplete = () => {
    onSeeDetail();
    setLoading(false);
  };

  const handleClickDetail = () => {
    if (loading) return;

    playMusic();
    setLoading(true);
  };

  useEffect(() => {
    // side-effect to show next page after guest data ready
    // @important we use interval to make sure guest data is ready first before we go to next section
    if (loading) {
      let intervalGuest = setInterval(() => {
        if (!isLoading) {
          clearInterval(intervalGuest);
          handleLoadingComplete();
        }
      }, 1500);
    }
  }, [loading, isLoading, isInvitation]);

  return (
    <Box
      minHeight="100vh"
      bgImage={GRADIENT_COVER}
      position="relative"
      overflow="hidden"
    >
      <Center width="100%" zIndex="2" height="100vh">
        <Box width="85%" position="relative" height="100%">
          <Box
            color="mainColorText"
            fontSize="10px"
            width="100%"
            maxW="500px"
            padding="24px"
            bgSize="cover"
            borderRadius="16px"
            paddingTop="166px"
          >
            <Box textAlign="center" color="mainColorText">
              <WithAnimation>
                <Box>
                  <Center margin="-22px 0 22px">
                    <Image src={ImgBismillah} width="140px" filter="invert(1)" />
                  </Center>
                </Box>
              </WithAnimation>
              <WithAnimation>
                <Box position="relative" padding="32px 0 8px 0">
                  <Heading
                    {...TITLE_SECTION_STYLES}
                    color="white"
                    position="relative"
                    width="120px"
                    margin="0 auto"
                    mt="-50px"
                    fontSize="40px"
                    textTransform="lowercase"
                  >
                    The 
                    <Box className="animation-up-and-down"  position="absolute" top="-20px" left="-12px" >
                      <Image src={ASSETS_BUTTERFLY} width="40px" zIndex="3" />
                    </Box>
                  </Heading>
                  <Heading
                    {...TITLE_SECTION_STYLES}
                    color="white"
                    padding="4px 0"
                    position="relative"
                    mt="-30px"
                    fontSize="40px"
                    textTransform="lowercase"
                  >
                    Wedding of
                  </Heading>
                </Box>
              </WithAnimation>
              <WithAnimation>
                <Text
                  {...HEADING_STYLES}
                  fontSize="36px"
                  color="white"
                  py="16px"
                  mt="16px"
                  fontStyle="italic"
                  marginTop="-18px"
                >
                  {THE_BRIDE}
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Text {...CONTENT_STYLES} color="white" fontSize="18px" >
                  {WEDDING_RESEPSI_FULLDATE}
                </Text>
              </WithAnimation>
            </Box>
          </Box>
        </Box>
      </Center>

      {/* Scrolldown section */}
      <Box pos="absolute" bottom="20vh" width="100%" zIndex="99">
        <WithAnimation>
          <ScrollToDown
            loading={loading}
            text={isInvitation ? txt.invitation[lang] : txt.announcement[lang]}
            marginTop="8px"
            {...BUTTON_PADDING_PROPS}
            fontStyle="italic"
            onClick={handleClickDetail}
          />
        </WithAnimation>
      </Box>
      
      {/* ASSETS: BUILDING */}
      <Image src={ASSETS_BUILDING} width="100%" position="absolute" bottom="0" zIndex="2" opacity="48%"></Image>
      <Box position="absolute" top="10px" right="-120px">
        <WithAnimation right>
          <Image src={ASSETS_FLOWER_1} width="240px" zIndex="3" transform="rotateY(180deg)"></Image>
        </WithAnimation>
      </Box>
      <Box className="animation__flower" position="absolute" top="100px" left="-135px" >
        <WithAnimation left>
          <Image src={ASSETS_FLOWER_1} width="270px" zIndex="3" transform="rotate(-12deg)"></Image>
        </WithAnimation>
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  onSeeDetail: func.isRequired,
};

export default Cover;
